/**
 * References:
 * https://dev.to/adamgoth/building-a-blog-with-gatsby-and-mdx-2eh9
 * https://github.com/adamgoth/adamgoth-gatsby
 * 
 * https://cesarvarela.com/blog/how-to-load-images-in-your-mdx-blog
 * https://stackoverflow.com/questions/67227977/gatsby-static-imagegatsby-plugin-image-inside-mdx
 * https://github.com/timhagn/gatsby-background-image/tree/main/packages/gbimage-bridge
 * https://nodejs.dev/learn/update-all-the-nodejs-dependencies-to-their-latest-version
 */

import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import components from "../components/styles.mdx"

import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import Seo from "../components/seo"

export default function PageTemplate({ data: { post, globalFeatureImage } }) {

  let pluginImage = getImage(post.frontmatter.featuredImage);
  if (post.frontmatter.featuredImage === null) {
    pluginImage = getImage(globalFeatureImage);
  }
  
  return (
    <section className="pb-5">
      <Seo 
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />

      
      <BgImage image={pluginImage} className="banner pt-5 pb-3 mt-0 align-items-bottom">
        <div className="container h-100">
          <div className="row justify-content-start align-items-end h-100">
            <div className="col-12 col-md-8  h-50 ">
              <h3 className="text-uppercase text-light mb-0 text-left">

              </h3>
              <h1 className="display-5 text-uppercase text-light text-left mb-2 mt-0">
                <strong>{post.frontmatter.title}</strong>
              </h1>
              <h3 className="text-left text-light text-uppercase mt-md-4 mt-3 mb-1">
                {post.frontmatter.date}
              </h3>
              <div
                className="btn-container-wrapper d-flex p-relative justify-content-start d-block text-right">
                <hr className="w-50 mb-1" />
              </div>
              <ul className="nav justify-content-start">
                <li className="nav-item">
                  
                </li>
                <li className="nav-item">
                  
                </li>
                <li className="nav-item">
                  
                </li>
              </ul>
            </div>
          </div>
        </div>
      </BgImage>  


      <div className="container">
        <MDXProvider components={components}>
          <MDXRenderer onlyExcerpt={false} localImages={post.frontmatter.embeddedLocalImages}>{post.body}</MDXRenderer>
        </MDXProvider>
      </div>
    </section>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    post: mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date
        description
        embeddedLocalImages {
          childImageSharp {
            gatsbyImageData
          }
        }
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    globalFeatureImage: file(
      relativePath: {eq: "pexels-alena-darmel-7862494.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
